import { useChangeKeyDataVM } from "presentation/hook/ChangeKeyData/useChangeKeyDataVM";
import { useChangeKeyDataTracked } from "presentation/store/ChangeKeyData/ChangeKeyDataProvider";
import { InputDropdown } from "veronica-ui-component/dist/component/core";


export const ChangeKeyDataEditForm = () => {

  const [changeKeyDataState] = useChangeKeyDataTracked();
  const updateHandlingStatus = changeKeyDataState.updateHandlingStatus
  const changeKeyDataVM = useChangeKeyDataVM();
  return (
      <>
          {/* <div className={'im-flex-row'}>
            <div className={'flex-row-item'}>
                <NbisCommonField
                //errorMessages={allFormState}
                isReadOnly={true}
                //isShowOptional={true}
                readOnlyValue={currentData.refCntrNo || ''}
                fieldValue={currentData.refCntrNo}
                fieldLabel={CHANGE_KEY_DATA_CONSTANT.OLD_CNTR_NO}
                //isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'refCntrNo'}
                //options={[]}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                  changeKeyDataVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
            </div>
          </div>
          <div className={'im-flex-row'}>
            <div className={'flex-row-item'}>
                <NbisCommonField
                //errorMessages={allFormState}
                isReadOnly={true}
                //isShowOptional={true}
                readOnlyValue={currentData.refBillCode || ''}
                fieldValue={currentData.refBillCode}
                fieldLabel={CHANGE_KEY_DATA_CONSTANT.OLD_BILL_CODE}
                //isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'refBillCode'}
                //options={[]}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                  changeKeyDataVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
            </div>
          </div>
          <div className={'im-flex-row'}>
          <div className={'flex-row-item'}>
                <DatePickerComponent
                    disabled={true}
                    label={CHANGE_KEY_DATA_CONSTANT.OLD_FIRST_IN_TML}
                    width="200px"
                    date={currentData.refFirstInTmlDatetime}
                    fieldName="refFirstInTmlDatetime"
                    errorMessage={""}
                    onDateChange={changeKeyDataVM.onFieldChange}/>
            </div>
          </div> */}

          <div className={'im-flex-row'}>
            <div className='im-flex-row-item'>
              <InputDropdown
                  label='Update Handling Status'
                  width='200px'
                  inputType="freeText"
                  mode={"single"}
                  value={updateHandlingStatus}
                  onChange={(e) => changeKeyDataVM.onDropdownChange(e)}
                  options={changeKeyDataState.dynamicOptions.handlingStatusDropdownOptions}/>
            </div>
          </div>
      </>
  )
}

import { ChangeKeyDataSearchCriteria } from "domain/entity/ChangeKeyData/ChangeKeyDataSearchCriteria";
import { ChangeKeyDataConstant } from "presentation/constant/ChangeKeyData/ChangeKeyDataConstant";
import { useChangeKeyDataVM } from "presentation/hook/ChangeKeyData/useChangeKeyDataVM";
import { useChangeKeyDataTracked } from "presentation/store/ChangeKeyData/ChangeKeyDataProvider";
import { useCallback, useState } from "react";
import { HPHButton, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarActionCross, SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import ChangeKeyDataSearchForm from "./ChangeKeyDataSearchForm";

const ChangeKeyDataSearchPanel = () => {
    const CHANGE_KEY_DATA_CONSTANT = ChangeKeyDataConstant.Charge;
    const changeKeyDataVM = useChangeKeyDataVM();
    const [changeKeyDataState] = useChangeKeyDataTracked();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const resetButtonClicked = useCallback(() => {
        changeKeyDataVM.onSearchCriteriaResetClick();
    }, [changeKeyDataVM]);
    
    const searchButtonClicked = useCallback((criteria:ChangeKeyDataSearchCriteria) => {
        setIsLoading(true);
        changeKeyDataVM.getChangeKeyDatas(criteria).then((data) => {
            localStorage.setItem("ChangeKeyDataSearchCriteria", JSON.stringify(criteria));
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        })
        
    }, [changeKeyDataVM])

    return (
        <div className='side-form-content-left-wrapper' style={{padding:"20px 20px 20px 0px"}}>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{CHANGE_KEY_DATA_CONSTANT.SEARCH_CRITERIA}</SidebarTitle>
                        <SidebarActionCross>
                            <IconButton
                                fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={changeKeyDataVM.onSearchClick} />
                        </SidebarActionCross>
                    </Sidebarheader>                
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" /> }

            <div className={'add-edit-form'} style={{ maxHeight:'75vh' , height:'75vh', overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        
                        {/* Stack Form Start */}
                        <ChangeKeyDataSearchForm/>
                    </div>                  
                </div>
            </div>
            <SidebarActionBar>
                <HPHButton label={'Reset'} size={'Small'} theme={'Secondary'} onClick={resetButtonClicked} />
                <HPHButton label={'Search'} size={'Small'} theme={'Primary'} onClick={() => searchButtonClicked(changeKeyDataState.searchCriteria)} />
            </SidebarActionBar>
        </div>
    );
}

export default ChangeKeyDataSearchPanel;

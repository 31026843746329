import { SelectionChangedEvent } from "ag-grid-community";
import { E_Custom_Dispatch_Event, E_Type_Of_Event, customDispatchEvent } from "helpers/Events";
import { INITIAL_CHANGE_KEY_DATA_CHARGE_INFO_COL_DEF, transferRowData } from "presentation/constant/ChangeKeyData/ChangeKeyDataChargeInfoColumnDefinition";
import { ChangeKeyDataConstant } from "presentation/constant/ChangeKeyData/ChangeKeyDataConstant";
import { useChangeKeyDataVM } from "presentation/hook/ChangeKeyData/useChangeKeyDataVM";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useChangeKeyDataTracked } from "presentation/store/ChangeKeyData/ChangeKeyDataProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useMemo, useRef } from "react";


const ChangeKeyDataChargeInfoTable: React.FC = () => {
    const [changeKeyDataState] = useChangeKeyDataTracked();
    const changeKeyDataVM = useChangeKeyDataVM();
    const [anaInfoState] = useANAInfoTracked();
    const gridRef: any = useRef(null);
    // const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    // useEffect(() => {
    //     if (!onTableSelectionClicked) return;

    //     gridRef?.current?.gridRef.current.api?.deselectAll();
    // }, [onTableSelectionClicked])


    const handClose = useCallback(() => {
        changeKeyDataVM.onInfoCloseClick();
    }, [changeKeyDataVM]);

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        // setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        changeKeyDataVM.updateChargeSelectedCharges(selectedRows);
    }, [changeKeyDataVM]);

    const tableDoubleClicked = useCallback((data: any) => {
        if (data) {
            customDispatchEvent(E_Type_Of_Event.CHARGE_DETAIL_REDIRECT_EVENT,
                E_Custom_Dispatch_Event.REDIRECT_CHARGE_DETAIL_FROM_CHANGE_KEY_DATA,
                {
                    userName: anaInfoState.userName,
                    data: data.data,
                });
        }
    }, [anaInfoState.userName]);

    const memoChangeKeyDataChargeInfoTable = useMemo(() => {


        return (
            <NbisTable
                id='change-key-data-charge-info-table'
                isNewColumnSetting={true}
                columns={INITIAL_CHANGE_KEY_DATA_CHARGE_INFO_COL_DEF}
                headerLabel={ChangeKeyDataConstant.Charge.CHARGE_DETAIL}
                headerActionButtons={[
                    {
                        id: 'onCloseButton',
                        icon: 'Icon-cross',
                        title: 'Close'
                    }
                ]}
                onCloseButton={handClose}
                //onRowClick={(e: any, changeKeyData: ChangeKeyDataEntity) => handleRowClick(changeKeyData)}
                onRowDoubleClick={(e: any) => tableDoubleClicked(e)}
                data={transferRowData(changeKeyDataState.changeKeyDataChargeInfos ?? [])}
                showPaginator={false}
                editable={false}
                showAddIcon={false}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                rowSelection={"multiple"}
                showActionButtons={true}
                onSelectionChanged={handleSelectionChange}
                gridHeight="customHeight"
                customHeight="calc(100vh - 135px)"
                ref={gridRef}
            />
        );
    }, [changeKeyDataState.changeKeyDataChargeInfos, handClose, handleSelectionChange, tableDoubleClicked])

    return <><TableWrapper>{memoChangeKeyDataChargeInfoTable}</TableWrapper></>;
}

export default memo(ChangeKeyDataChargeInfoTable);

import { MessageConstant } from "presentation/constant/MessageConstant";
import { useChangeKeyDataVM } from "presentation/hook/ChangeKeyData/useChangeKeyDataVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useChangeKeyDataTracked } from "presentation/store/ChangeKeyData/ChangeKeyDataProvider";
import { useCallback } from "react";
import { HPHButton, Loader } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import ChangeKeyDataChargeInfoTable from "./ChangeKeyDataChargeInfoTable";


export const ChangeKeyDataChargeInfoTablePanel = () => {
  const [ changeKeyDataState ] = useChangeKeyDataTracked();
  const changeKeyDataVM = useChangeKeyDataVM();
  const messageBarVM = useMessageBarVM();
  // const [isLoading, setIsLoading] = useState(false);
  const {selectedChangeKeyDatas,changeKeyDataChargeInfos,selectChargeInfos,searchCriteria} = changeKeyDataState;
  const [anaInfoState] = useANAInfoTracked();
  const {allowUpdate} = anaInfoState;

//   useEffect(() => {
//     const initialScreen = async() => {
//         if (selectedChangeKeyDatas[0].key === "") return;
//         setIsLoading(true);
//         try {
//             const results = await Promise.allSettled([
//               changeKeyDataVM.getChangeKeyDataChargeInfos(selectedChangeKeyDatas[0]),
//             ]);
//             results.forEach((result, index) => {
//                 if (index === 0 && result.status === 'fulfilled') {

//                 } else if (index === 1 && result.status === 'rejected') {

//                 }
//             });
//         } catch(error) {
//         }
//     }
//     initialScreen().then((data) => {            
//       setIsLoading(false);
//     }).catch(error => {            
//       setIsLoading(false);
//     });
// }, [selectedChangeKeyDatas, changeKeyDataVM, messageBarVM]);

const handleApply = useCallback(async() => {
  // setIsSave(true);
  if(selectedChangeKeyDatas.length === 0){
      messageBarVM.showWarining(MessageConstant.common.SELECT_A_RECORD_AT_LEAST);
      return;
  }
  if(selectedChangeKeyDatas.length > 1){
      messageBarVM.showWarining(MessageConstant.charge.SEL_ONE_CHANGE_KEY_DATA);
      return;
  }
  if(selectChargeInfos.length < 1){
      messageBarVM.showWarining(MessageConstant.charge.SEL_ONE_CHARGE_DTL_DATA_APPLY);
      return;
  }
  changeKeyDataVM.onShowLoading();
  changeKeyDataVM.onApplyClick(searchCriteria,selectedChangeKeyDatas[0],changeKeyDataChargeInfos,selectChargeInfos).then((data) => {
      if (data && data === "success") {
        changeKeyDataVM.onHideLoading();
        messageBarVM.showSuccess("Apply successful.");
      }else{
        messageBarVM.showError(data);
      }
  }).finally(() => {
      changeKeyDataVM.onHideLoading();
  })
}, [changeKeyDataChargeInfos, changeKeyDataVM, messageBarVM, searchCriteria, selectChargeInfos, selectedChangeKeyDatas]);

const isDisableApply = () => {
  if (changeKeyDataState.selectedChangeKeyDatas.length > 0 && changeKeyDataState.selectChargeInfos.length > 0) return false;
  return true;
}

  return <>
      {(/*isLoading || */changeKeyDataState.isLoading) && <Loader Indicator="Spinner" size="Medium" /> }
        <div className="main-comp-wrapper">
          <ChangeKeyDataChargeInfoTable/>
          
            {
              <Sidebarheader style={{width: '100%', display:"flex", alignItems:"center", justifyContent: "flex-end"}}>
                <StyledAction className="tm-animated-wrapper">
                    {(allowUpdate) && <HPHButton disabled={isDisableApply()} label={"Apply"} size={'Small'} theme={'Primary'} onClick={handleApply} />}
                </StyledAction>
              </Sidebarheader>
            }
        </div>
  </>
}
// export default ChangeKeyDataChargeInfoTablePanel;

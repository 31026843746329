import { SelectionChangedEvent } from "ag-grid-community";
import { ChangeKeyDataEntity } from "domain/entity/ChangeKeyData/ChangeKeyDataEntity";
import { ChangeKeyDataSearchCriteria, EMPTY_CHANGE_KEY_DATA_SEARCH_CRITERIA } from "domain/entity/ChangeKeyData/ChangeKeyDataSearchCriteria";
import { DEFAULT_DATE_RANGE } from "domain/repository/apiUtil";
import moment from "moment";
import { INITIAL_CHANGE_KEY_DATA_COL_DEF, transferRowData } from "presentation/constant/ChangeKeyData/ChangeKeyDataColumnDefinition";
import { ChangeKeyDataConstant } from "presentation/constant/ChangeKeyData/ChangeKeyDataConstant";
import { MessageConstant } from "presentation/constant/MessageConstant";
import { useChangeKeyDataVM } from "presentation/hook/ChangeKeyData/useChangeKeyDataVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useChangeKeyDataTracked } from "presentation/store/ChangeKeyData/ChangeKeyDataProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";


const ChangeKeyDataTablePanel: React.FC = () => {
    const [changeKeyDataState] = useChangeKeyDataTracked();
    const changeKeyDataVM = useChangeKeyDataVM();
    const gridRef: any = useRef(null);
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    const messageBarVM = useMessageBarVM();
    const { selectedChangeKeyDatas } = changeKeyDataState;

    useEffect(() => {
        if (!onTableSelectionClicked) return;

        gridRef.current?.gridRef.current.api?.deselectAll();
    }, [onTableSelectionClicked])

    useEffect(() => {
        // const columnDefs = (INITIAL_CHANGE_KEY_DATA_COL_DEF.slice());

        if (!changeKeyDataState.selectedChangeKeyDatas ||
            changeKeyDataState.selectedChangeKeyDatas.length <= 0) {
            //gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
            if (!onTableSelectionClicked) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
        }
    })

    useEffect(() => {
        const initialScreen = async () => {
            try {
                changeKeyDataVM.onShowLoading();
                const cacheSearchCriteria = localStorage.getItem("ChangeKeyDataSearchCriteria");
                let results;
                if (cacheSearchCriteria) {
                    results = await Promise.allSettled([
                        changeKeyDataVM.getChangeKeyDatas(JSON.parse(cacheSearchCriteria) as ChangeKeyDataSearchCriteria),
                    ]).finally(() => {
                        changeKeyDataVM.onHideLoading();
                    });
                } else {


                    let newSearchCriteria = { ...EMPTY_CHANGE_KEY_DATA_SEARCH_CRITERIA }
                    newSearchCriteria = {
                        ...newSearchCriteria,
                        createdDateFrom: moment().subtract(DEFAULT_DATE_RANGE, "days").startOf("day").toDate(),
                        createdDateTo: moment().endOf("day").toDate(),
                    };

                    results = await Promise.allSettled([
                        changeKeyDataVM.getChangeKeyDatas(newSearchCriteria),
                    ]);
                }
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    } else if (index === 1 && result.status === 'rejected') {

                    }
                })
            } catch (error) {
            }
        }
        initialScreen().then((data) => {
            changeKeyDataVM.onHideLoading();
        }).catch(error => {
            changeKeyDataVM.onHideLoading();
        });
    }, [changeKeyDataVM])



    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();
        changeKeyDataVM.updateSelectedCharges(selectedRows);
    }, [changeKeyDataVM])

    const handleRowClick = useCallback((data: ChangeKeyDataEntity) => {
        changeKeyDataVM.handleRowClick(data);
    }, [changeKeyDataVM])

    const tbBtns = useMemo(() => {
        let btns = [];
        if (!changeKeyDataState.isShowEditPanel) {
            btns.push({
                id: 'onSearch',
                icon: 'Icon-search',
                title: 'Search'
            });
        }
        if (changeKeyDataState.selectedChangeKeyDatas.length > 0 && !changeKeyDataState.isShowEditPanel) {
            btns.push({
                id: 'onEdit',
                icon: 'Icon-pen',
                title: 'Edit'
            })
        }
        return btns;
    }, [changeKeyDataState.isShowEditPanel, changeKeyDataState.selectedChangeKeyDatas])

    const handleSearch = useCallback(() => {
        changeKeyDataVM.onSearchClick();
    }, [changeKeyDataVM]);

    const handleEdit = useCallback(() => {
        if (selectedChangeKeyDatas.length === 0) {
            messageBarVM.showWarining(MessageConstant.common.SELECT_A_RECORD_AT_LEAST);
            return;
        }
        changeKeyDataVM.onEditClick();
    }, [changeKeyDataVM, messageBarVM, selectedChangeKeyDatas]);

    const memoChangeKeyDataTable = useMemo(() => {

        return (
            <NbisTable
                id='change-key-data-table'
                isNewColumnSetting={true}
                headerLabel={ChangeKeyDataConstant.Charge.CHANGE_KEY_DATA}
                columns={INITIAL_CHANGE_KEY_DATA_COL_DEF.slice()}
                data={transferRowData(changeKeyDataState.changeKeyDatas ?? [])}
                headerActionButtons={tbBtns}
                showPaginator={false}
                editable={false}
                showAddIcon={false}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={false}
                rowSelection={"multiple"}
                isRowHighligted={true}
                onRowClick={(e: any) => handleRowClick(e.data)}
                onSelectionChanged={handleSelectionChange}
                gridHeight="customHeight"
                customHeight="calc(100vh - 75px)"
                ref={gridRef}
                onSearch={handleSearch}
                onEdit={handleEdit}
            />
        );
    }, [changeKeyDataState.changeKeyDatas, handleRowClick, handleSelectionChange, handleSearch, handleEdit, tbBtns])

    return <><TableWrapper>
        {/* {isLoading && <Loader Indicator="Spinner" size="Medium" /> } */}
        {memoChangeKeyDataTable}</TableWrapper></>;
}

export default memo(ChangeKeyDataTablePanel);

import { useChangeKeyDataVM } from "presentation/hook/ChangeKeyData/useChangeKeyDataVM";
import { useChangeKeyDataTracked } from "presentation/store/ChangeKeyData/ChangeKeyDataProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import ChangeKeyDataSearchPanel from "./LeftPanel/ChangeKeyDataSearchPanel";
import ChangeKeyDataRightPanel from "./RightPanel/ChangeKeyDataRightPanel";


const ChangeKeyDataMaintenance: React.FC = () => {
    const [changeKeyDataState] = useChangeKeyDataTracked();
    const { isShowCriteriaPanel } = changeKeyDataState;
    const changeKeyDataVM = useChangeKeyDataVM();
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const initialScreen = async () => {
            try {

                const results = await Promise.allSettled([
                    changeKeyDataVM.loadDropdownOption(),
                ]);
                results.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    } else if (index === 1 && result.status === 'rejected') {

                    }
                })
            } catch (error) {
            }
        }
        initialScreen().then((data) => {
            setIsLoading(false)
        }).catch(error => {
            setIsLoading(false)
        });
    }, [changeKeyDataVM])



    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>
        <div className={`main-comp-wrapper${isShowCriteriaPanel ? '' : ' im-hide-side-form-draggable'}`}>


            <SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={isShowCriteriaPanel ? "25%" : "0%"}
                rightSectionWidth={isShowCriteriaPanel ? "75%" : "100%"}
                leftChildren={<ChangeKeyDataSearchPanel />}
                rightChildren={<ChangeKeyDataRightPanel />}
            />
        </div>
    </>
}

export default memo(ChangeKeyDataMaintenance);